import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
// Form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
//mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, DialogActions, DialogTitle } from '@mui/material';

//component
import FormProvider, { RHFTextField } from '@components/hook-form';
import { getMyProfile, updateMyProfile } from '@api/main';
import { getErrorMessage } from '@api/handleApiError';
import CustomDialog from '@components/CustomDialog';

type Props = {
  open: boolean;
  close: (mode: string) => void;
};

export default function MyProfileModal({ open, close }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    userName: '',
    firstName: '',
    lastName: '',
  };

  const ModalSchema = yup.object().shape({
    firstName: yup.string().required('Trường này không được để trống'),
    lastName: yup.string().required('Trường này không được để trống'),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ModalSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getMyProfile();
        reset({
          ...defaultValues,
          ...{
            userName: res.data.userName,
            userType: res.data.userTypeId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email || '',
          },
        });
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: any) => {
    try {
      delete data.userName;
      delete data.userType;

      await updateMyProfile(data);
      close('SAVE');
      enqueueSnackbar('Thành công!', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    }
  };

  return (
    <CustomDialog open={open} onClose={() => close('')}>
      <DialogTitle>Hồ sơ cá nhân</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <RHFTextField name="userName" disabled label={'Tên người dùng'} />

          <RHFTextField
            name="firstName"
            label={
              <Box sx={{ display: 'flex', direction: 'row' }}>
                Họ
                <Box sx={{ color: '#FF4842' }}>&nbsp;*</Box>
              </Box>
            }
          />
          <RHFTextField
            name="lastName"
            label={
              <Box sx={{ display: 'flex', direction: 'row' }}>
                Tên
                <Box sx={{ color: '#FF4842' }}>&nbsp;*</Box>
              </Box>
            }
          />
        </Stack>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
          >
            Lưu
          </LoadingButton>
          <Button variant="outlined" color="inherit" onClick={() => close('')}>
            Hủy
          </Button>
        </DialogActions>
      </FormProvider>
    </CustomDialog>
  );
}
