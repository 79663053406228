import { useState } from 'react';
// @mui
import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// routes
// components
import { IconButtonAnimate } from '@components/animate';
import defaultAvatar from '@assets/avatar-blank.png';
import MenuPopover from '@components/menu-popover';
import { getUserInfoAC, logoutAC } from '@store/slices/auth';
import MyProfileModal from './MyProfileModal';
import ChangePasswordModal from './ChangePasswordModal';
import { useDispatch, useSelector } from 'src/store/store';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const [myProfileModalOpen, setMyProfileModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const user = useSelector((state) => state.auth.userData);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const closeProfileModal = (modeResult: any) => {
    setMyProfileModalOpen(false);
    if (modeResult === 'SAVED') {
      dispatch(getUserInfoAC());
    }
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = () => {
    dispatch(logoutAC());
    handleClosePopover();
  };

  const openChagePasswordModal = () => {
    setChangePasswordModalOpen(true);
    handleClosePopover();
  };

  const openProfileModal = () => {
    setMyProfileModalOpen(true);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={defaultAvatar} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 260, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.userName}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => openProfileModal()}>Hồ sơ cá nhân</MenuItem>
          <MenuItem onClick={() => openChagePasswordModal()}>Đổi mật khẩu</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Đăng xuất
        </MenuItem>
      </MenuPopover>

      {myProfileModalOpen && <MyProfileModal open={myProfileModalOpen} close={closeProfileModal} />}
      {changePasswordModalOpen && (
        <ChangePasswordModal open={changePasswordModalOpen} close={closeChangePasswordModal} />
      )}
    </>
  );
}
