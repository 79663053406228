import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

type ConfirmDialogProps = {
  open: boolean;
  title?: React.ReactElement | string;
  message?: React.ReactElement | string;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
};

type ConfirmDialogProviderProps = {
  children: React.ReactNode;
};

type DialogConfigT = {
  title?: React.ReactElement | string;
  message?: React.ReactElement | string;
  actionCallback?: (agr: unknown) => void;
};

type DialogContextT = {
  openDialog?: ({ title, message, actionCallback }: DialogConfigT) => void;
  resetDialog?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
};

const ConfirmationDialog = ({ open, title, message, onConfirm, onClose }: ConfirmDialogProps) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{message}</DialogContent>

    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        Hủy
      </Button>
      <Button variant="contained" onClick={onConfirm}>
        Đồng ý
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmationDialogContext = React.createContext<DialogContextT>({});

const ConfirmationDialogProvider = ({ children }: ConfirmDialogProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfigT>({});

  const openDialog = ({ title, message, actionCallback }: DialogConfigT) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    if (dialogConfig.actionCallback) dialogConfig.actionCallback(true);
  };

  const onClose = () => {
    resetDialog();
    if (dialogConfig.actionCallback) dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onClose={onClose}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const showConfirmationDialog = ({ ...options }) =>
    new Promise((res) => {
      openDialog?.({ actionCallback: res, ...options });
    });

  return { showConfirmationDialog };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
