import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '@auth/AuthGuard';
// layouts
import CompactLayout from '@layouts/compact';
import DashboardLayout from '@layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '@configs/config';
//
import { LoginPage, Page404, Users, ResetPasswordPage, VerifyCodePage } from './elements';
import Dashboard from '@pages/dashboard';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'verify-code', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
