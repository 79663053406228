import { ElementType, lazy, Suspense } from 'react';
// components
import LoadingScreen from '@components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('@pages/auth/Login')));
export const VerifyCodePage = Loadable(lazy(() => import('@pages/auth/VerifyCodePage')));
export const ResetPasswordPage = Loadable(lazy(() => import('@pages/auth/ResetPasswordPage')));

export const Dashboard = Loadable(lazy(() => import('@pages/dashboard')));
export const Users = Loadable(lazy(() => import('@pages/users')));

export const Page404 = Loadable(lazy(() => import('@pages/misc/Page404')));
