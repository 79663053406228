import {
  ApiPagingResponseModel,
  ChangePasswordModel,
  CreateUserApiInputModel,
  ExchangeRateChartInputModel,
  ExchangeRateTableInputModel,
  ForgotPasswordApiInputModel,
  RegisterApiInputModel,
  ResendConfirmationApiInputModel,
  ResetPasswordApiInputModel,
  SetEnabledUserApiInputModel,
  UpdateProfileModel,
  UpdateUserApiInputModel,
  UserConfirmationResponseModel,
  UserLoginInputModel,
  UserLoginResponseModel,
  UserResponseModel,
  VerifyEmailApiInputModel,
} from '@api/types';
import axios, { handleRefreshToken } from './base';

//#region AUTHENTICATION API

export const loginAPI = async (data: UserLoginInputModel) =>
  axios.post<UserLoginResponseModel>(`/auth/login`, data, {
    __auth: false,
  });

export const registerAPI = async (data: RegisterApiInputModel, lang: string) =>
  axios.post(`/auth/register-clinic?lang=${lang}`, data, {
    __auth: false,
  });

export const verifyEmailAPI = async (data: VerifyEmailApiInputModel) =>
  axios.post('/auth/verify', data, {
    __auth: false,
  });

export const resendConfirmationAPI = async (data: ResendConfirmationApiInputModel, lang: string) =>
  axios.post(`/auth/resend-confirmation?lang=${lang}`, data, { __auth: false });

export const getUserInfoAPI = async () => axios.get('/users/me');

export const forgotPasswordAPI = async (data: ForgotPasswordApiInputModel, lang: string) =>
  axios.post<UserConfirmationResponseModel>(`/auth/forgot-password?lang=${lang}`, data);

export const resetPasswordAPI = async (data: ResetPasswordApiInputModel, lang: string) =>
  axios.post(`/auth/reset-password?lang=${lang}`, data);

export const refreshToken = async () => handleRefreshToken();

//#endregion

// #region Users

export const getUserListAPI = async (keyword: string, pageSize: number, pageNumber: number) =>
  axios.get<ApiPagingResponseModel<UserResponseModel>>(
    `/users?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );

export const createUserAPI = async (data: CreateUserApiInputModel) => axios.post('/users', data);

export const setEnabledUserAPI = async (userId: string, data: SetEnabledUserApiInputModel) =>
  axios.put(`/users/${userId}/set-enabled`, data);

export const deleteUserAPI = async (userId: string) => axios.delete(`/users/${userId}`);

export const updateUserAPI = async (userId: string, data: UpdateUserApiInputModel) =>
  axios.put(`/users/${userId}`, data);
// #endregion

export const getExchangeRateApi = async (data: ExchangeRateTableInputModel) => {
  return axios.get(`/exchange-rates?dateTimeUnix=${data.dateTime}`);
};

export const getExchangeRateChartApi = async (data: ExchangeRateChartInputModel) => {
  return axios.post(`/exchange-rates/get-statics`, data, {});
};

export const changeMyPasswordAPI = async (data: ChangePasswordModel) => {
  return axios.put(`/users/change-password`, data);
};

export const getMyProfile = async () => {
  return axios.get(`/users/me`);
};

export const updateMyProfile = async (data: UpdateProfileModel) => {
  return axios.put(`users/me`, data);
};
