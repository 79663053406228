export const StatusEnum = {
  Reserved: 1,
  Assigned: 2,
};

export const StatusEnumDisplayConfig = {
  [StatusEnum.Assigned]: { title: 'Assigned' },
  [StatusEnum.Reserved]: { title: 'Reserved' },
};

export enum OrderByTypeEnum {
  Asc = 0,
  Desc = 1,
}

export const KeywordOperatorEnum = {
  StartWith: 1,
  EndsWith: 2,
  Contain: 3,
  Equals: 4,
  Sku: 5,
};

export const KeywordOperatorDisplayConfig = {
  [KeywordOperatorEnum.StartWith]: { title: 'Start with' },
  [KeywordOperatorEnum.EndsWith]: { title: 'End with' },
  [KeywordOperatorEnum.Contain]: { title: 'Contain' },
  [KeywordOperatorEnum.Equals]: { title: 'Equals' },
  [KeywordOperatorEnum.Sku]: { title: 'SKU' },
};

export const EditInternalSKUsReasonEnum = {
  ProductFinalConfig: 1,
  ErrorCorrection: 2,
  Other: 3,
};

export const EditInternalSKUsReasonDisplayConfig = {
  [EditInternalSKUsReasonEnum.ProductFinalConfig]: { title: 'Product final configuration' },
  [EditInternalSKUsReasonEnum.ErrorCorrection]: { title: 'Error correction' },
  [EditInternalSKUsReasonEnum.Other]: { title: 'Other' },
};

export const ProductStatusEnum = {
  Activated: 1,
  Deactivated: 2,
};

export const ProductStatusDisplayConfig = {
  [ProductStatusEnum.Activated]: { title: 'Activated' },
  [ProductStatusEnum.Deactivated]: { title: 'Deactivated' },
};

export const BANK = {
  VietcomBank: 1,
  TechcomBank: 2,
  VietinBank: 3,
};

export const BANK_COLORS = {
  [BANK.VietcomBank]: '#E9FCD4',
  [BANK.TechcomBank]: '#FFE7D9',
  [BANK.VietinBank]: '#D1EDFF',
};

export const BANK_LOGO = {
  [BANK.VietcomBank]: {
    full: '/vietcombank.png',
    sort: '/VCB.png',
  },
  [BANK.TechcomBank]: {
    full: '/techcombank.png',
    sort: '/TCB.png',
  },
  [BANK.VietinBank]: {
    full: '/vietinbank.png',
    sort: '/VTB.png',
  },
};

export enum FILLTER_TIME_ENUMS {
  Last7Days = 1,
  Last30Days = 2,
}

export enum UserTypeEnum {
  SuperAdmin = 0,
  Staff = 1,
}
export enum TRANSACTION_STATUS {
  CashBuying = 1,
  TransferBuying = 2,
  Sellings = 3,
}

export const CURRENCY = {
  USD: {
    code: 'USD',
    flag: '/Flag/USD.png',
    id: 1,
  },
  EUR: {
    code: 'EUR',
    flag: '/Flag/EUR.png',
    id: 2,
  },
  GBP: {
    code: 'GBP',
    flag: '/Flag/GBP.png',
    id: 3,
  },
  JPY: {
    code: 'JPY',
    flag: '/Flag/JPY.png',
    id: 4,
  },
  AUD: {
    code: 'AUD',
    flag: '/Flag/AUD.png',
    id: 5,
  },
  SGD: {
    code: 'SGD',
    flag: '/Flag/SGD.png',
    id: 6,
  },
  THB: {
    code: 'THB',
    flag: '/Flag/THB.png',
    id: 7,
  },
  CAD: {
    code: 'CAD',
    flag: '/Flag/CAD.png',
    id: 8,
  },
  CHF: {
    code: 'CHF',
    flag: '/Flag/CHF.png',
    id: 9,
  },
  HKD: {
    code: 'HKD',
    flag: '/Flag/HKD.png',
    id: 10,
  },
  CNY: {
    code: 'CNY',
    flag: '/Flag/CNY.png',
    id: 11,
  },
  DKK: {
    code: 'DKK',
    flag: '/Flag/DKK.png',
    id: 12,
  },
  INR: {
    code: 'INR',
    flag: '/Flag/INR.png',
    id: 13,
  },
  KRW: {
    code: 'KRW',
    flag: '/Flag/KRW.png',
    id: 14,
  },
  KWD: {
    code: 'KWD',
    flag: '/Flag/KWD.png',
    id: 15,
  },
  MYR: {
    code: 'MYR',
    flag: '/Flag/MYR.png',
    id: 16,
  },
  NOK: {
    code: 'NOK',
    flag: '/Flag/NOK.png',
    id: 17,
  },
  RUB: {
    code: 'RUB',
    flag: '/Flag/RUB.png',
    id: 18,
  },
  SAR: {
    code: 'SAR',
    flag: '/Flag/SAR.png',
    id: 19,
  },
  SEK: {
    code: 'SEK',
    flag: '/Flag/SEK.png',
    id: 20,
  },
  LAK: {
    code: 'LAK',
    flag: '/Flag/LAK.png',
    id: 21,
  },
  NZD: {
    code: 'NZD',
    flag: '/Flag/NZD.png',
    id: 22,
  },
};

export const UserRoleTypeEnum = {
  SuperAdmin: 0,
  Staff: 1,
};
