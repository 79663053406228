// Known error code
export const ERROR_CODES = {
  NEED_VERIFY_ACCOUNT: 'PINT_17',
  INVALID_EMAIL_OR_PASSWORD: 'PINT_44',
};

// All api error
export const API_ERRORS = {
  PINT_17: {
    message: 'You need to verify your account',
  },
};

export const getErrorMessage = (error: any, defaultMessage = 'Unknown Error') => {
  if (error?.response?.metadata) {
    return `${error.response.metadata.messages[0].value}`;
  }
  return error.message || defaultMessage;
};

export const hasError = (error: any, errorCode: string) =>
  !!error?.response?.metadata?.messages?.find((msg: any) => msg.code === errorCode);
