// ----------------------------------------------------------------------

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  resetPassword: '/reset-password',
  verifyCode: '/verify-code',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dasboard: '/dashboard',
  users: '/users',
};
