// routes
import { PATH_DASHBOARD } from '@routes/paths';
// components
import Iconify from '@components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: icon('material-symbols:dashboard'),
  users: icon('mdi:user'),
};

const superAdminNavConfig = [
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard },
      { title: 'Người dùng', path: PATH_DASHBOARD.users, icon: ICONS.users },
    ],
  },
];

const navConfig = [
  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard }],
  },
];

export { superAdminNavConfig, navConfig };
